<script lang="ts" setup>
import AppIcon from '../../icon/AppIcon.vue';
import { useOptionsList } from '../composables/useOptionsList';
import type { SelectOptionChildren, SelectOptionsEmits } from '../types';
import type { AppSelectOptionsProps } from './types';

const props = withDefaults(defineProps<AppSelectOptionsProps>(), {
  rows: 5,
  isInFocus: false,
  observeScrollToEnd: false,
  size: 'medium',
});

const emit = defineEmits<SelectOptionsEmits>();

const {
  containerRef,
  listStyle,
  customHeadRef,
  emptyStateVisible,
  emptyStateRef,
  optionsGroupLabelsRef,
  optionRef,
  focusValue,
  observeElement,
  optionHasGroupLabel,
  getOptionIndex,
  setFocusOnHover,
} = useOptionsList({
  props,
  emit,
});

const chosenOption = computed(() => Array.isArray(props.choseValue) ? props.choseValue.map(item => item.name) : []);
const classList = computed(() => {
  return [`app-option-list__size-${props.size}`];
});

function isChosenValue(option: SelectOptionChildren) {
  return chosenOption.value.includes(option.name);
}
</script>

<template>
  <div
    ref="containerRef"
    class="app-option-list"
    :style="listStyle"
    :class="classList">
    <div
      v-if="customHead"
      ref="customHeadRef"
      class="app-option-list__head">
      <component :is="customHead()" />
    </div>

    <div
      v-if="emptyStateVisible"
      ref="emptyStateRef"
      class="app-options-list__empty">
      <div
        class="app-option-list__empty-label">
        {{ emptyState?.label }}
      </div>
      <div
        class="app-option-list__empty-content">
        {{ emptyState?.message }}
      </div>
    </div>

    <div
      v-for="(item, index) in options"
      :key="index"
      class="app-option-list__item">
      <div
        v-if="optionHasGroupLabel(item)"
        ref="optionsGroupLabelsRef"
        class="app-option-list__item-label">
        {{ item.label }}
      </div>

      <div
        v-for="(option, optionIndex) in item.children"
        :id="`${getOptionIndex(optionIndex, index)}`"
        :key="option.name"
        ref="optionRef"
        class="app-option-list__option"
        :class="{
          active: option.name === focusValue?.name,
          disabled: option.disabled,
        }"
        @mouseover="setFocusOnHover(option)"
        @click.stop.prevent="emit('onOptionSelect', option)">
        <template v-if="customOptions?.length">
          <component :is="customOptions[optionIndex]" />
        </template>

        <div v-else class="app-option-list__option-item">
          <div class="app-option-list__option-item__label">
            {{ option.label }}
          </div>
        </div>
        <div
          v-show="isChosenValue(option)"
          class="app-option-list__option-item__chosen-icon">
          <AppIcon name="done" />
        </div>
      </div>
    </div>

    <div ref="observeElement" />
  </div>
</template>

<style lang="scss" src="./style.scss" />
